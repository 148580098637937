(function($) {

    'use strict';

    /**
     * Popup object
     */
    App.Popup = {

        gallerySelector: '.gallery-popup',
        imageSelector: '.image-popup',
        imageGallerySelector: '.image-popup-gallery',
        videoSelector: '.video-popup',
        nextLabel: 'Volgende',
        previousLabel: 'Vorige',

        imagePopupOptions: {
            closeMarkup: '<a title="%title%" class="mfp-close"><span>Sluiten</span></a>',
            image: {
                titleSrc: function(item) {
                    return item.el.find('img').attr('alt');
                }
            },
            type: 'image',
            zoom: {
                easing: 'ease-in-out',
                enabled: true,
                duration: 200
            }
        },

        videoPopupOptions: {
            closeMarkup: '<a title="%title%" class="mfp-close"><span>Sluiten</span></a>',
            disableOn: 767,
            type: 'iframe',
            zoom: {
                easing: 'ease-in-out',
                enabled: true,
                duration: 20000
            }
        },

        /**
         * Initialise the object
         */
        init: function() {
            this.bind();
        },

        /**
         * Set up event handlers
         */
        bind: function() {

            var self = this;

            // Image popup
            $(this.imageSelector).magnificPopup(this.imagePopupOptions);

            // Gallery popup
            $(this.gallerySelector).each(function() {
                $(this).magnificPopup($.extend({
                    delegate: self.imageGallerySelector,
                    gallery: {
                        arrowMarkup: '<button title="%title%" type="button" class="dd mfp-arrow mfp-arrow-%dir%"></button>',
                        enabled: true,
                        tCounter: '<span class="mfp-counter">%curr% / %total%</span>',
                        tPrev: self.previousLabel,
                        tNext: self.nextLabel
                    },
                }, self.imagePopupOptions));
            });

            // Video popup
            $(this.videoSelector).magnificPopup(this.videoPopupOptions);
        }
    }

}(jQuery));